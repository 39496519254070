
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Center, UpdateCenter } from '../models/center.model'
import { AuthService } from './auth.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NewBooking } from '../models/new-booking'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly url = `${environment.API}/api`

  private loginResponse = new BehaviorSubject<any>({});
  result$ = this.loginResponse.asObservable()

  private countrySubject = new BehaviorSubject<string>('')
  country$ = this.countrySubject.asObservable()

  constructor(
    private http: HttpClient,
    private authService: AuthService) { }


  setHeader(token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Credentials', 'true')

    return headers
  }

  // Result MSAL  
  get resultValue() {
    return this.loginResponse.value
  }

  saveResult(result: any) {
    this.loginResponse.next(result)
  }

  // Set CountryID
  setCountryId(value: string) {
    this.countrySubject.next(value)
  }


  // 1 - Center List
  listCenter(instructorMail: string, token: string): Observable<Center> {
    return this.http.get<Center>(`${this.url}/Center/list/${instructorMail}`,
      { headers: this.setHeader(token) }
    )
  }


  // 2 - Booking New
  createNewBooking(countryId: string, centerId: string, bodyParams: NewBooking): Observable<any> {

    const token = sessionStorage.getItem('token-AgendamentoOnline')!

    return this.http.post<NewBooking>(`${this.url}/Booking/new/${countryId}/${centerId}`, bodyParams,
      { headers: this.setHeader(token) }
    )
  }

  // 3 - Update Center
  updateCenter(bodyParams: UpdateCenter, countryId: string): Observable<any> {

    const token = sessionStorage.getItem('token-AgendamentoOnline')!

    return this.http.patch(`${this.url}/Booking/Update`, bodyParams, {
      headers: this.setHeader(token),
      params: { countryId }
    })
  }
}
