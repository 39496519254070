import { DadosService } from 'src/app/services/dados.service'
import { Center } from './../../models/center.model'
import { Component, Input, EventEmitter } from '@angular/core'
import { Output } from '@angular/core'
import { SelectCenter } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-lista-unidades',
  templateUrl: './lista-unidades.component.html',
  styleUrls: ['./lista-unidades.component.scss'],
})
export class ListaUnidadesComponent {

  language: SelectCenter = this.languageService.selectCenter

  @Input() unidades: Center[] = [];
  @Output() onSelectCenter = new EventEmitter<any>();
  @Output() formStatus = new EventEmitter<boolean>()

  unidadeSelecionada = '';

  constructor(
    private dados: DadosService,
    private languageService: LanguageService
  ) { }

  onSelecionaUndiade(e: any) {
    this.unidadeSelecionada = e.centerId

    this.dados.setDados('center', e)

    this.onSelectCenter.emit(e)
    this.formStatus.emit(true)
  }
}
