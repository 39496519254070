import { FormControl, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms'
import { Component, Output, EventEmitter } from '@angular/core'
import { DadosService } from 'src/app/services/dados.service'
import { SelectService } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'

@Component({
  selector: 'app-modalidades',
  templateUrl: './modalidades.component.html',
  styleUrls: ['./modalidades.component.scss']
})
export class ModalidadesComponent {

  language: SelectService = this.languageService.selectService

  @Output() formStatus = new EventEmitter<boolean>()

  types = [
    {
      name: this.language.serviceOffline,
      value: 'offline',
      icon: 'fa-user-friends'
    },
    {
      name: this.language.serviceOnline,
      value: 'online',
      icon: 'fa-laptop'
    }
  ]

  form!: FormGroup

  constructor(
    private fb: FormBuilder,
    private dados: DadosService,
    private languageService: LanguageService
  ) {
    this.form = this.fb.group({
      typesChecked: new FormArray([], [Validators.required]),
      typeSelected: new FormControl('')
    })
  }

  onCheckboxChange(event: any) {

    const meetingType = (this.form.controls['typesChecked'] as FormArray)

    if (event.target.checked) {
      meetingType.push(new FormControl(event.target.value))

    } else {
      const index = meetingType.controls
        .findIndex(x => x.value === event.target.value)
      meetingType.removeAt(index)
    }

    console.log(this.form.valid)

    this.formStatus.emit(this.form.valid)

    this.getCheckedValueAsString()

  }

  getCheckedValueAsString() {

    let types = this.form.value.typesChecked

    if (types.length === 2) {
      this.form.patchValue({
        typeSelected: 'both'
      })
    } else {
      this.form.patchValue({
        typeSelected: types[0]
      })
    }

    this.saveData(this.form.value.typeSelected)

  }

  saveData(value: string) {
    this.dados.setDados('booking', { meetingType: value })
  }

}
