import { ApiService } from './../../services/api.service'
import { DadosService } from './../../services/dados.service'
import { Component, Inject, OnInit } from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'
import { concatMap, delayWhen, filter, take, tap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { DOCUMENT } from '@angular/common'
import { AuthService } from 'src/app/services/auth.service'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { LanguageService } from 'src/app/services/language.service'
import { Page, SuccessMessage } from 'src/app/models/language.model'
import { UpdateCenter } from 'src/app/models/center.model'
import { interval } from 'rxjs'

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'

type StatusBooking = 'loaded' | 'success' | 'error'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        maxHeight: '2000px'
      })),
      state('closed', style({
        height: '0'
      })),
      transition('closed => open', [
        animate('300ms ease')
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {

  language!: Page
  languageSuccessMessage!: SuccessMessage

  countryId!: string
  token = ''
  displayUnidade = ''
  centers!: any
  isCenterSelected: boolean = false

  isLoading: boolean = true
  titleLoading: string = ''
  // textLoading: string = this.languageService.page.pageLoading
  textLoading: string = ''

  newBookingFinished: StatusBooking = 'loaded'
  txtMessageError: string = ''

  labelConfirmButton!: string
  isButtonConfirm!: boolean

  isFormValid = {
    unidade: false,
    duracao: false,
    diasDisponiveis: false,
    fusoHorario: false,
    modalidades: false,
  }

  loginDisplay = false

  isAllCenterActive = false

  bookingData = {
    bearerToken: '',
    timezone: '',
    duration: 0,
    meetingType: '',
    workingHours: [
      {
        day: '',
        timeSlots: [
          {
            end: '',
            start: '',
          },
        ],
      },
    ],
  }

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private authMsal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private dados: DadosService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    // console.log('isFormValid OnInit', this.isFormValid)
    this.dados.setDados('booking', this.bookingData)

    // MSAL Methods
    this.msalSubject()
    this.msalInProgress()
    this.getProfile()


  }

  getAccount() {
    this.authService.authAppointment()
      .pipe(

        tap(result => {
          this.token = result
        }),

        concatMap(() => this.authService.authAgendamentoOnline()),

        concatMap(() => this.apiService.result$),

      )
      .subscribe({
        next: res => {

          console.log('Subscribe', res)

          this.dados.setDados('booking', { bearerToken: res.accessToken })

          if (res.account.username) {
            this.listCenter(res.account.username, this.token, res.accessToken)
          }

        },
        error: err => {
          console.log('Subscribe', err)
        },
      })
  }

  listCenter(username: string, token: string, accessToken: string) {

    this.apiService.listCenter(username, token)
      .pipe(

        tap((res: any) => {

          let allCenterStatus = res.map((item: any) => item.active)

          this.centers = res
          this.countryId = res[0].countryId
          this.apiService.setCountryId(res[0].countryId)
          this.isAllCenterActive = allCenterStatus.every(Boolean)

        }),

        concatMap((res: any) => {

          let centerIds = res.map((item: any) => item.centerId)

          this.updateCenter(
            {
              bearerToken: accessToken,
              centerIds: centerIds
            },
            res[0].countryId
          )

          return res
        }),

        delayWhen(() => {
          return this.isAllCenterActive ? interval(5000) : interval(100)
        }),

        tap(() => {

          if (this.isAllCenterActive) {
            this.openMyBooking()
          }
        }),

        concatMap(() => this.apiService.country$),

        tap(res => {
          this.language = this.languageService.page
          this.languageSuccessMessage = this.languageService.successMessage
          this.labelConfirmButton = this.languageService.page.buttonConfirm
        }),

        take(1)
      )
      .subscribe({
        next: (res: any) => {
          console.log(res)
          this.isLoading = false
        },
        error: err => {
          this.newBookingFinished = 'error'
          this.txtMessageError = this.languageService.errorMessage.activatedMessage
          this.isLoading = false
        }
      })
  }

  updateCenter(body: UpdateCenter, countryId: string) {
    this.apiService.updateCenter(body, countryId).subscribe(
      res => console.log(res)
    )
  }

  createBooking() {
    if (!this.isButtonConfirm) return

    this.isLoading = true
    this.titleLoading = this.languageService.page.titleLoading
    this.textLoading = this.languageService.page.createBookingLoading

    this.apiService
      .createNewBooking(
        this.countryId,
        this.dados.getDados('center').centerId,
        this.dados.getDados('booking')
      )
      .subscribe({
        next: res => {
          console.log('NEW BOOKING', res)

          this.displayUnidade = this.dados.getDados('center').centerName

          this.newBookingFinished = 'success'
          this.isLoading = false
        },
        error: err => {
          console.error('ERROR BOOKING', err)

          this.newBookingFinished = 'error'
          this.txtMessageError = this.languageService.errorMessage.sendMessage

          this.isLoading = false
        },
      })
  }

  getFormStatus(status: boolean, component: string) {

    this.isFormValid = {
      ...this.isFormValid,
      [component]: status
    }

    console.log(this.isFormValid)

    let allFormValid = Object.values(this.isFormValid).every(Boolean)

    console.log('form OK', allFormValid)

    this.isButtonConfirm = allFormValid

  }

  openMyBooking() {
    return this.document.location.replace('https://outlook.office.com/bookings/calendar')
  }


  // **** MSAL configurations ****

  msalSubject() {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => { console.log('msalSubject', result) })
  }

  msalInProgress() {
    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {

        this.getAccount()

        this.loginDisplay = this.authMsal.instance.getAllAccounts().length > 0
      })
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT).subscribe({
      next: (profile: any) => {

        console.log(profile)

      },
      error: err => {
        // console.log(err.error)

        // if (err.status === 500) {
        //   return this.document.location.replace('https://portal.unidadekumon.com.br')
        // }
      },
    })
  }

  async onSelectCenter() {
    await this.showCenterContent()

    window.scroll({
      top: 300,
      left: 0,
      behavior: "smooth",
    })
  }

  showCenterContent() {
    return new Promise<void>((resolve) => {
      this.isCenterSelected = true

      setTimeout(() => {
        resolve()
      }, 250)

    })

  }

  tryAgain() {
    window.location.reload()
  }
}
