import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() icon: string = ''
  @Input() linkText: string = ''
  altText: string = ''


  ngOnInit(): void {

    switch (this.icon) {

      case 'success':
        this.icon = '../../../assets/images/icon-success.svg'
        this.altText = 'Ícone sucesso'
        break

      case 'error':
        this.icon = '../../../assets/images/icon-error.svg'
        this.altText = 'Ícone erro'
        break

      default:
        break

    }

  }

}
