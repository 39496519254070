<app-header></app-header>

<app-loading *ngIf="isLoading; else conteudo" [textLoading]="textLoading" [titleLoading]="titleLoading"></app-loading>

<ng-template #conteudo [ngSwitch]="newBookingFinished">
  <div class="container-xl">
    <h1 class="page-title">{{language.title}}</h1>
    <div class="conteudo">
      <ng-container *ngSwitchCase="'loaded'">
        <div class="step centers" #target>
          <div class="col-label">
            <app-label [title]="language.stepSelectCenter"></app-label>
          </div>

          <div class="col-form">
            <app-lista-unidades
              [unidades]="centers"
              (onSelectCenter)="onSelectCenter()"
              (formStatus)="getFormStatus($event, 'unidade')"></app-lista-unidades>
          </div>
        </div>

        <div [@openClose]="isCenterSelected ? 'open' : 'closed'">
          <div class="step">
            <div class="col-label">
              <app-label [title]="language.stepServiceDuration"></app-label>
            </div>

            <div class="col-form">
              <app-duracao-atendimento
                (formStatus)="getFormStatus($event, 'duracao')"></app-duracao-atendimento>
            </div>
          </div>

          <div class="step">
            <div class="col-label">
              <app-label [title]="language.stepAvailableDays"></app-label>
            </div>

            <div class="col-form">
              <app-dias-disponiveis
                (formStatus)="getFormStatus($event, 'diasDisponiveis')"></app-dias-disponiveis>
            </div>
          </div>

          <div class="step">
            <div class="col-label">
              <app-label [title]="language.stepTimezone"></app-label>
            </div>

            <div class="col-form">
              <app-fuso-horario
                (formStatus)="getFormStatus($event, 'fusoHorario')"></app-fuso-horario>
            </div>
          </div>

          <div class="step">
            <div class="col-label">
              <app-label [title]="language.stepSelectService"></app-label>
            </div>

            <div class="col-form">
              <app-modalidades
                (formStatus)="getFormStatus($event, 'modalidades')"></app-modalidades>
            </div>
          </div>

          <div class="btn-confirmar">
            <app-button
              [label]="labelConfirmButton"
              leftIcon="fa-check"
              [disabled]="!isButtonConfirm"
              (click)="createBooking()"></app-button>
          </div>
        </div>
      </ng-container>

      <app-message
        *ngSwitchCase="'success'"
        icon="success"
        [linkText]="languageSuccessMessage.textLink">
        <p>
          {{languageSuccessMessage.message}}
          <span> {{ displayUnidade }}</span>
        </p>
        <app-button
          class="btnOpenMyBooking"
          (click)="openMyBooking()"
          [label]="languageSuccessMessage.textButton"></app-button>
      </app-message>

      <app-message
        *ngSwitchCase="'error'"
        icon="error">
        <p class="textMessageError">{{ txtMessageError }}</p>
        <!-- <a class="tryAgain" href="">Tente novamente em alguns minutos.</a> -->
        <!-- <app-button (click)="tryAgain()" label="Tentar novamente"></app-button> -->
      </app-message>
    </div>
  </div>
</ng-template>

<app-version></app-version>