import { AbstractControl, FormArray, FormGroup } from '@angular/forms'

export function timeSlotValidate(control: AbstractControl) {


    const start = control.get('start')?.value
    const end = control.get('end')?.value

    return start < end
        ? null
        : { invalidTimeSlot: true }


}

export function atLeastOne(control: AbstractControl) {
    const invalid = (control as FormArray).controls.some(c => c.value.key == '')

    console.log(invalid)


    return invalid ?
        { 'atLeastOne': true } :
        null
}