import { Pipe, PipeTransform } from '@angular/core'
import { LanguageService } from '../services/language.service'
import { AvailableDays } from '../models/language.model'

@Pipe({
  name: 'displayDayName'
})
export class DisplayDayNamePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  language: AvailableDays = this.languageService.availableDays

  week = [
    {
      day: 'monday',
      displayName: this.language.monday
    },
    {
      day: 'tuesday',
      displayName: this.language.tuesday
    },
    {
      day: 'wednesday',
      displayName: this.language.wednesday
    },
    {
      day: 'thursday',
      displayName: this.language.thursday
    },
    {
      day: 'friday',
      displayName: this.language.friday
    },
    {
      day: 'saturday',
      displayName: this.language.saturday
    },
    {
      day: 'sunday',
      displayName: this.language.sunday
    }
  ]


  transform(value: unknown, ...args: unknown[]): unknown {

    let day = this.week.findIndex(item => item.day === value)

    return this.week[day].displayName
  }

}
