import { DadosService } from 'src/app/services/dados.service'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms'
import { Component, EventEmitter, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'app-fuso-horario',
  templateUrl: './fuso-horario.component.html',
  styleUrls: ['./fuso-horario.component.scss']
})
export class FusoHorarioComponent implements OnInit {

  @Output() formStatus = new EventEmitter<boolean>()

  listTimeZone: any = []
  optionSelected: boolean = false

  data = [
    {
      country: 'BR',
      timezones: [
        {
          name: 'Brasília (UTC-03:00)',
          value: 'E. South America Standard Time'
        },
        // {
        //   name: 'Araguaína (UTC-03:00)',
        //   value: 'Tocantins Standard Time'
        // },
        {
          name: 'Fortaleza (UTC-03:00) ',
          value: 'SA Eastern Standard Time'
        },
        {
          name: 'Salvador (UTC-03:00)',
          value: 'Bahia Standard Time'
        },
        {
          name: 'Cuiabá (UTC-04:00)',
          value: 'Central Brazilian Standard Time'
        },
        {
          name: 'Manaus (UTC-04:00)',
          value: 'SA Western Standard Time'
        },
        {
          name: 'Rio Branco (UTC-05:00)',
          value: 'SA Pacific Standard Time'
        }
      ]
    },
    {
      country: 'AR',
      timezones: [
        {
          name: 'Argentina (UTC-03:00)',
          value: 'Argentina Standard Time'
        }
      ]
    },
    {
      country: 'BO',
      timezones: [
        {
          name: 'Bolívia (UTC-04:00)',
          value: 'SA Western Standard Time'
        }
      ]
    },
    {
      country: 'CL',
      timezones: [
        {
          name: 'Chile (UTC-05:00)',
          value: 'Pacific SA Standard Time'
        }
      ]
    },
    {
      country: 'CO',
      timezones: [
        {
          name: 'Colômbia (UTC-05:00)',
          value: 'SA Pacific Standard Time'
        }
      ]
    },
    {
      country: 'PE',
      timezones: [
        {
          name: 'Peru (UTC-05:00)',
          value: 'SA Pacific Standard Time'
        }
      ]
    },
    {
      country: 'UY',
      timezones: [
        {
          name: 'Uruguay (UTC-03:00)',
          value: 'Montevideo Standard Time'
        }
      ]
    }
  ]

  form = new FormGroup({
    fusoHorario: new FormControl('', [Validators.required])
  })

  constructor(
    private dados: DadosService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.getCountryId()
    console.log('fuso horario', this.form.valid)
  }

  getCountryId() {
    this.apiService.country$.subscribe(res => {
      this.filterTimezoneByCountryId(res)
    })
  }


  filterTimezoneByCountryId(country: string) {

    let timezone = this.data.find((item: any) => {
      return item.country === country
    })

    this.listTimeZone = timezone?.timezones

    console.log('this.listTimeZone', this.listTimeZone)

    this.setFormDisabled()
    this.setValueDisabledForm()

  }

  onSelect() {
    this.dados.setDados('booking', { timezone: this.form.value.fusoHorario })
    this.formStatus.emit(this.form.valid)
  }

  async setValueDisabledForm() {

    let isFormDisabled = this.form.get('fusoHorario')?.disabled

    try {
      await this.getDadosOnInit()

      if (isFormDisabled) {

        this.form.setValue({
          fusoHorario: this.listTimeZone[0].value
        })

        this.dados.setDados('booking', { timezone: this.listTimeZone[0].value })
        this.formStatus.emit(true)

      }

      console.log('raw value disabled: ', this.form.getRawValue().fusoHorario)


    } catch (error) {
      console.log('Não há dados na sessão.')
    }

  }

  getDadosOnInit() {

    return new Promise<void>((resolve, reject) => {

      if (this.dados.hasDados('booking')) {
        resolve()
      } else {
        reject()
      }
    })
  }

  setFormDisabled() {
    if (this.listTimeZone.length > 1) {
      this.form.enable()
    } else {
      this.form.get('fusoHorario')?.disable()
      this.optionSelected = true
    }
  }

}
