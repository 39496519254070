import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { DadosService } from 'src/app/services/dados.service'

import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration'

dayjs.extend(duration)
@Component({
  selector: 'app-duracao-atendimento',
  templateUrl: './duracao-atendimento.component.html',
  styleUrls: ['./duracao-atendimento.component.scss']
})
export class DuracaoAtendimentoComponent {

  durations = [
    {
      hours: 0,
      minutes: 30
    },
    {
      hours: 0,
      minutes: 45
    },
    {
      hours: 1,
      minutes: 0
    },
    {
      hours: 1,
      minutes: 30
    }
  ]

  @Output() formStatus = new EventEmitter<any>()

  @ViewChild('form') form!: NgForm

  constructor(
    private dados: DadosService
  ) { }

  getDuratonValue(value: any) {

    let minutes = dayjs.duration(value).asMinutes()

    this.saveData(minutes)
    this.formStatus.emit(this.form.valid)
  }


  saveData(value: number) {
    if (!this.dados.hasDados('booking')) return

    this.dados.setDados('booking', { duration: value })
  }


}
