<div class="lista-unidades">
  <div class="unidade" *ngFor="let unidade of unidades" [ngSwitch]="unidade.active" [ngClass]="{
      unidadeSel: unidadeSelecionada === unidade.centerId
    }" [id]="unidade.centerId">
    <span class="nome">{{ unidade.centerName }}</span>

    <ng-container *ngSwitchCase="true">
      <div class="status active">
        <i class="fas fa-check"></i><span>{{ language.statusActive }}</span>
      </div>
      <div class="acao">
        <a href="https://outlook.office.com/bookings/calendar" class="link">
          {{ language.linkToBooking }}
        </a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <div class="status"><i class="fas fa-clock"></i><span>{{ language.statusInactive }}</span></div>
      <div class="acao">
        <button type="button" class="btn" (click)="onSelecionaUndiade(unidade)">
          {{ language.buttonSetUp }}
        </button>
      </div>
    </ng-container>

  </div>
</div>