import { Injectable } from '@angular/core'
import { AvailableDays, ErrorMessage, Page, SelectCenter, SelectService, SuccessMessage } from '../models/language.model'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  countryLanguage!: string

  constructor(private apiService: ApiService) {
    this.apiService.country$.subscribe(res => this.countryLanguage = res)
  }

  selectLanguage(arr: any[]) {

    const findCountry = arr.find(item => item.country === this.countryLanguage)
    const countryNotFound = arr.find(item => item.country === 'HH')

    return findCountry ? findCountry : countryNotFound
  }


  get page() {

    let content: Page[] = [
      {
        country: 'BR',
        title: 'Agendamento de Atendimento da Unidade',
        buttonConfirm: 'Confirmar',
        titleLoading: 'Enviando os dados.',
        pageLoading: 'Carregando',
        createBookingLoading: 'Dentro de alguns segundos o Agendamento Online estará disponível para sua unidade.',
        stepSelectCenter: 'Selecione uma unidade',
        stepServiceDuration: 'Duração do atendimento',
        stepAvailableDays: 'Indique os dias disponíveis para agendamentos',
        stepTimezone: 'Selecione o fuso horário',
        stepSelectService: 'Selecione as modalidades'
      },
      {
        country: 'HH',
        title: 'Programación de Servicio de Unidad',
        buttonConfirm: 'Confirmar',
        titleLoading: 'Enviando los datos.',
        pageLoading: 'Cargando',
        createBookingLoading: 'En unos segundos, la programación Online estará disponible para su unidad.',
        stepSelectCenter: 'Seleccione una unidad',
        stepServiceDuration: 'Duración del servicio',
        stepAvailableDays: 'Indique los días disponibles para citas',
        stepTimezone: 'Seleccione la zona horaria',
        stepSelectService: 'Seleccione las modalidades'
      }
    ]

    return this.selectLanguage(content)
  }

  get selectCenter() {

    let content: SelectCenter[] = [
      {
        country: 'BR',
        statusActive: 'Agendamento já configurado',
        statusInactive: 'Agendamento não configurado',
        buttonSetUp: 'Configurar',
        linkToBooking: 'Acessar página de agendamento'
      },
      {
        country: 'HH',
        statusActive: 'Programación ya configurada',
        statusInactive: 'Programación no configurada',
        buttonSetUp: 'Configurar',
        linkToBooking: 'Acceder a la página de reservas'
      }
    ]

    return this.selectLanguage(content)
  }

  get availableDays() {

    let content: AvailableDays[] = [
      {
        country: 'BR',
        unavailable: 'Indisponível',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
        sunday: 'Domingo',
        from: 'De:',
        to: 'Até:',
        inputError: 'Escolha uma hora de término posterior à hora de início.'
      },
      {
        country: 'HH',
        unavailable: 'Indisponible',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        from: 'De:',
        to: 'Hasta:',
        inputError: 'Elija una hora de finalización posterior a la hora de inicio.'
      }
    ]

    return this.selectLanguage(content)
  }

  get selectService() {

    let content: SelectService[] = [
      {
        country: 'BR',
        serviceOffline: 'Presencial',
        serviceOnline: 'Remoto'
      },
      {
        country: 'HH',
        serviceOffline: 'En persona',
        serviceOnline: 'Remoto'
      }
    ]

    return this.selectLanguage(content)
  }

  get successMessage() {
    let content: SuccessMessage[] = [
      {
        country: 'BR',
        message: 'Agendamento Online habilitado com sucesso para a unidade',
        textButton: 'Abrir meu agendamento online',
        textLink: 'Habilitar agendamento para outra unidade'
      },
      {
        country: 'HH',
        message: 'Programación Online habilitada con éxito para la unidad',
        textButton: 'Abrir mi cita online',
        textLink: 'Habilitar programación para otra unidad'
      }
    ]

    return this.selectLanguage(content)
  }

  get errorMessage() {
    let content: ErrorMessage[] = [
      {
        country: 'BR',
        activatedMessage: 'Aconteceu algum problema e por isso não foi possível ativar o Agendamento Online. Entre em contato com o suporte.',
        sendMessage: 'Aconteceu algum problema e por isso não foi possível enviar os dados do agendamento. Entre em contato com o suporte.'
      },
      {
        country: 'HH',
        activatedMessage: 'Hubo un problema y por lo tanto no fue posible activar la Programación en línea. Póngase en contacto con el soporte.',
        sendMessage: 'Hubo un problema y por lo tanto no fue posible enviar los datos de la cita. Póngase en contacto con el soporte.'
      }
    ]

    return this.selectLanguage(content)
  }

}
