import { Injectable } from '@angular/core';

// const KEY = 'booking'

@Injectable({
  providedIn: 'root'
})
export class DadosService {

  hasDados(key: string) {
    return !!this.getDados(key);
  }

  setDados(key: string, obj: any) {

    if (!this.hasDados(key)) {
      sessionStorage.setItem(key, JSON.stringify(obj))
    } else {
      sessionStorage.setItem(key, JSON.stringify(Object.assign(this.getDados(key), obj)))
    }
  }

  getDados(key: string) {
    const data = sessionStorage.getItem(key)
    return JSON.parse(data!)
  }

  removeDados(key: string) {
    sessionStorage.removeItem(key)
  }
}
