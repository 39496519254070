import { DadosService } from './../../services/dados.service'
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms'
import { timeSlotValidate } from './timeSlot.validator'

import * as _ from 'lodash'
import { AvailableDays } from 'src/app/models/language.model'
import { LanguageService } from 'src/app/services/language.service'


@Component({
  selector: 'app-dias-disponiveis',
  templateUrl: './dias-disponiveis.component.html',
  styleUrls: ['./dias-disponiveis.component.scss']
})
export class DiasDisponiveisComponent implements OnInit {

  language: AvailableDays = this.languageService.availableDays

  @Output() formStatus = new EventEmitter<boolean>()

  listTimeSlot: string[] = []

  daysForm!: FormGroup

  content = [
    {
      day: "monday",
      timeSlots: []
    },
    {
      day: "tuesday",
      timeSlots: []
    },
    {
      day: "wednesday",
      timeSlots: []
    },
    {
      day: "thursday",
      timeSlots: []
    },
    {
      day: "friday",
      timeSlots: []
    },
    {
      day: "saturday",
      timeSlots: []
    },
    {
      day: "sunday",
      timeSlots: []
    }
  ]

  constructor(
    private fb: FormBuilder,
    private dados: DadosService,
    private languageService: LanguageService
  ) {

    this.daysForm = this.fb.group({
      workingHours: this.fb.array([])
    })

  }

  get daysFormControls(): FormArray {
    return this.daysForm.get('workingHours') as FormArray
  }

  getTimeSlotControls(index: number): FormArray {
    return this.daysFormControls.controls[index]?.get('timeSlots') as FormArray
  }

  ngOnInit(): void {

    this.createForm()
    this.generateInterval()

  }

  createForm() {
    this.content.forEach(item => {
      this.daysFormControls.push(
        this.fb.group({
          day: [item.day],
          timeSlots: this.fb.array([])
        })
      )
    })
  }

  addTimeSlot(index: number) {

    this.getTimeSlotControls(index).push(
      this.fb.group({
        start: new FormControl('09:00', Validators.required),
        end: new FormControl('18:00', Validators.required)
      },
        {
          validators: [timeSlotValidate]
        }
      )
    )

    this.getValidTimeSlots()

  }

  removeTimeSlot(index: number, pos: number) {
    this.getTimeSlotControls(index).removeAt(pos)
    this.getValidTimeSlots()
  }

  getValidTimeSlots() {

    let validDays = this.daysForm.value.workingHours.filter((item: any) => {
      return item.timeSlots.length > 0
    })

    if (this.daysForm.valid && validDays.length > 0) {
      console.log('VALIDO', validDays)
    } else {
      console.log('INVALIDO', validDays)
    }

    this.formStatus.emit(this.daysForm.valid && validDays.length > 0)

    this.dados.setDados('booking', { workingHours: validDays })

  }

  generateInterval() {

    let start = 60 * 6
    let end = 60 * 22.5
    let interval = 30

    for (let i = 0; start < end; i++) {
      if (start > end) break

      var hh = Math.floor(start / 60) // getting hours of day in 0-24 format

      var mm = start % 60 // getting minutes of the hour in 0-55 format

      this.listTimeSlot[i] = `${('0' + (hh % 24)).slice(-2)}:${('0' + mm).slice(-2)}`

      start = start + interval
    }
  }


}
