
import { MsalGuard } from '@azure/msal-angular'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './pages/home/home.component'
import { BrowserUtils } from '@azure/msal-browser'
import { CheckComponent } from './pages/check/check.component'

const routes: Routes = [

  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'check', component: CheckComponent },
  { path: '**', redirectTo: '' },
]

// const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
