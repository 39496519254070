import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'

import { environment } from 'src/environments/environment'
import { map } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private appSecretKey: string = environment.appSecretKey
  private api = `${environment.API}/api/Authentication`

  params = new HttpParams().append('appSecretKey', this.appSecretKey)

  private userAppointment = {
    id: 0,
    username: environment.usernameAppointment,
    password: environment.passwordAppointment,
    role: "string"
  }

  private userAgendamentoOnline = {
    id: 0,
    username: environment.usernameAgendamentoOnline,
    password: environment.passwordAgendamentoOnline,
    role: "string"
  }

  constructor(private http: HttpClient) { }

  authAppointment() {

    return (
      this.http.post<string>(`${this.api}/login`, this.userAppointment, { params: this.params })
        .pipe(
          map((data: any) => {
            sessionStorage.setItem('token-appointment', data.token)
            return data.token
          })
        ))
  }

  authAgendamentoOnline() {

    return (
      this.http.post<string>(`${this.api}/login`, this.userAgendamentoOnline, { params: this.params })
        .pipe(
          map((data: any) => {
            sessionStorage.setItem('token-AgendamentoOnline', data.token)
            return data.token
          })
        ))
  }

  whoAmI() {
    return this.http.get<string>(`${this.api}/whoami`)
  }


}
