export const environment = {
  production: false,
  API: 'https://agendamentoapitst.kumon.com.br',
  URI: 'https://agendamentotst.unidadekumon.com.br',

  usernameAppointment: "Appointment",
  passwordAppointment: "!$%appt#4h6KfY/mkv)[D%GtNSu(sR*I{",

  usernameAgendamentoOnline: "AgendamentoOnline",
  passwordAgendamentoOnline: "z;PZ>#54jF6_vWm2DxB@eH9Q<XNTwu[UdVn%aER3$S}yY8fpG7",

  appSecretKey: "J[cL$nvFuE%N9HMpZDQ]RBhm>q;_fTtP@r6j3A8Ua<edk4zG@x",
}
