<form #form="ngForm">
  <div class="box-duracao">
    <label
      class="full-label label-duracao"
      *ngFor="let duration of durations; let i = index">
      <input
        type="radio"
        name="duracao"
        [id]="'duration-' + i"
        (change)="getDuratonValue(duration)"
        required
        ngModel
        [value]="duration" />
      <span class="duration">
        {{ "0" + duration.hours }}<span>:</span>{{ duration.minutes === 0
        ? duration.minutes + "0"
        : duration.minutes }}</span>
    </label>
  </div>
</form>