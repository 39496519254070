<form [formGroup]="form">
  <div class="box-modalidade">
    <label
      *ngFor="let type of types; let i = index"
      class="full-label label-modalidade"
    >
      <input
        type="checkbox"
        [value]="type.value"
        formArrayName="typesChecked"
        (change)="onCheckboxChange($event)"
      />
      <div>
        <i class="fas {{ type.icon }} icon"></i>
        <span class="type">{{ type.name }}</span>
        <span class="check checked"><i class="fas fa-check"></i></span>
        <!-- <span class="check unchecked"><i class="fas fa-minus"></i></span> -->
      </div>
    </label>
  </div>
</form>
