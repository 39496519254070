import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HeaderComponent } from './shared/header/header.component'
import { HomeComponent } from './pages/home/home.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ButtonComponent } from './shared/button/button.component'
import { LabelComponent } from './shared/label/label.component'
import { LoadingComponent } from './shared/loading/loading.component'
import { InputErrorComponent } from './shared/input-error/input-error.component'
import { ListaUnidadesComponent } from './components/lista-unidades/lista-unidades.component'
import { DuracaoAtendimentoComponent } from './components/duracao-atendimento/duracao-atendimento.component'
import { DiasDisponiveisComponent } from './components/dias-disponiveis/dias-disponiveis.component'
import { FusoHorarioComponent } from './components/fuso-horario/fuso-horario.component'
import { ModalidadesComponent } from './components/modalidades/modalidades.component'
import { MessageComponent } from './shared/message/message.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { DisplayDayNamePipe } from './pipes/display-day-name.pipe'

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { environment } from 'src/environments/environment'
import { CheckComponent } from './pages/check/check.component'
import { VersionComponent } from './shared/version/version.component'

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LabelComponent,
    ButtonComponent,
    LoadingComponent,
    InputErrorComponent,
    ListaUnidadesComponent,
    DuracaoAtendimentoComponent,
    DiasDisponiveisComponent,
    FusoHorarioComponent,
    ModalidadesComponent,
    MessageComponent,
    DisplayDayNamePipe,
    CheckComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: '51ff592f-1d6b-41b9-824a-4c22204e9e7d',
        authority: 'https://login.microsoftonline.com/c27f427d-7da5-4e40-97dc-8fde809e0c29',
        redirectUri: environment.URI //Enter_the_Redirect_Uri_Here'
        // redirectUri: 'http://localhost:4200' //Enter_the_Redirect_Uri_Here' //TODO Mudar antes de fazer o commit
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    { provide: 'googleTagManagerId', useValue: 'GTM-MM6WRRR' }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
