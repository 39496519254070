<div class="box-dias-disponiveis">

  <form [formGroup]="daysForm">

    <ng-container formArrayName="workingHours">

      <div class="row-day" *ngFor="let day of daysFormControls.controls; let i = index" [formGroupName]="i">
        <label>{{ day.value.day | displayDayName }}</label>

        <div class="slot" formArrayName="timeSlots">
          <div class="time" *ngFor="let slot of day.value.timeSlots; let s = index" [formGroupName]="s">

            <div>
              <span>{{ language.from }}</span>
              <select formControlName="start" (change)="getValidTimeSlots()">
                <option [value]="null" [hidden]="true"></option>
                <option *ngFor="let time of listTimeSlot" [value]="time">
                  {{ time }}
                </option>
              </select>
              <span>{{ language.to }}</span>
              <select formControlName="end" (change)="getValidTimeSlots()">
                <option [value]="null" [hidden]="true"></option>
                <option *ngFor="let time of listTimeSlot" [value]="time">
                  {{ time }}
                </option>
              </select>
              <button type="button" class="btn" (click)="removeTimeSlot(i, s)">
                <i class="fas fa-trash"></i>
              </button>
            </div>


            <ng-container
              *ngIf="
            getTimeSlotControls(i).controls[s]?.invalid &&
              (getTimeSlotControls(i).controls[s].dirty ||
              getTimeSlotControls(i).controls[s].touched)
            ">
              <!-- <app-input-error
                *ngIf="getTimeSlotControls(i).controls[s].get('start')?.errors?.['required'] ||
                  getTimeSlotControls(i).controls[s].get('end')?.errors?.['required']"
                textError="Período incompleto."></app-input-error> -->

              <app-input-error *ngIf="getTimeSlotControls(i).controls[s].errors?.['invalidTimeSlot']"
                [textError]="language.inputError"></app-input-error>
            </ng-container>

          </div>

          <span class="closed" *ngIf="day.value.timeSlots.length < 1">{{ language.unavailable }}</span>

          <button type="button" class="btn addButton" *ngIf="day.value.timeSlots.length < 3" (click)="addTimeSlot(i)">
            <i class="fas fa-plus"></i>
          </button>

        </div>
      </div>
    </ng-container>

  </form>
</div>